import React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Link } from "gatsby"
import {
  PageHeader,
  Section,
  Content,
  Row,
  Col,
} from "../styles/StyledElements"
const { slugFormat } = require("../utilities/slugFormat")

export default function ContentIndex({ data }) {
  const title = "Content Index"

  return (
    <Layout>
      <Seo title={title} />
      <PageHeader>
        <Content>
          <Row>
            <Col>
              <h1>{title}</h1>
            </Col>
          </Row>
        </Content>
      </PageHeader>
      <Section>
        <Content>
          <Row>
            <Col>
              {/* <p
      dangerouslySetInnerHTML={{
        __html: data.content.nodes[0].data.Content.childMarkdownRemark.html,
      }}
    /> */}

              <h2>Forecasts</h2>
              {data.forecasts.nodes.map(node => (
                <div key={node.recordId}>
                  <Link to={`/forecasts/${slugFormat(node.data.Name)}`}>
                    <h3>{node.data.Name}</h3>
                  </Link>
                  {/* <p>{node.data.Summary}</p> */}
                  <ul>
                    {node.data.Trends?.map(trend => (
                      <li key={trend.recordId}>
                        <Link to={`/trends/${slugFormat(trend.data.Name)}`}>
                          <strong>{trend.data.Name}</strong>
                        </Link>
                        <ul>
                          {trend.data.Signals?.map(signal => (
                            <li key={signal.recordId}>
                              <Link
                                to={`/signals/${slugFormat(signal.data.Name)}`}
                              >
                                <span>{signal.data.Name}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </Col>
          </Row>
        </Content>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  {
    forecasts: allAirtable(filter: { table: { eq: "Forecasts" } }) {
      nodes {
        recordId
        data {
          Name
          Category_Header
          Summary
          Trends {
            recordId
            data {
              Name
              Summary
              Signals {
                recordId
                data {
                  Name
                  Sector
                  Tags
                  # Sector_linked
                }
              }
            }
          }
        }
      }
    }
  }
`
